var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    {
      tag: "component",
      staticClass: "btn",
      class: _vm.classes,
      attrs: { type: _vm.tag === "button" ? _vm.type : "" },
      on: { click: _vm.handleClick }
    },
    [
      _vm.$slots.icon || (_vm.icon && _vm.$slots.default)
        ? _c(
            "span",
            { staticClass: "btn-inner--icon" },
            [_vm._t("icon", [_c("i", { class: _vm.icon })])],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.$slots.default ? _c("i", { class: _vm.icon }) : _vm._e(),
      _vm._v(" "),
      _vm.$slots.icon || (_vm.icon && _vm.$slots.default)
        ? _c(
            "span",
            { staticClass: "btn-inner--text" },
            [
              _vm._t("default", [
                _vm._v("\n        " + _vm._s(_vm.text) + "\n      ")
              ])
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.$slots.icon && !_vm.icon ? _vm._t("default") : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }