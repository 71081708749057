var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["feed-wrapper"] },
    [
      _vm._t("default"),
      _vm._v(" "),
      !_vm.isEmpty
        ? _c(
            "div",
            { staticClass: "list-pagination-component" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex-grow text-muted d-flex align-items-center small"
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("global.total_result_found_with_record", {
                        attribute: _vm.meta.total,
                        from: _vm.meta.from,
                        to: _vm.meta.to
                      })
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "base-dropdown",
                {
                  staticClass: "per-page-component",
                  attrs: {
                    tag: "div",
                    direction: "up",
                    "menu-classes": "show-dropdown-up",
                    position: "right"
                  }
                },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "mr-3",
                      attrs: {
                        slot: "title",
                        type: "button",
                        size: _vm.pagination.size,
                        design: "primary",
                        "data-toggle": "dropdown",
                        role: "button"
                      },
                      slot: "title"
                    },
                    [
                      _vm._v(_vm._s(_vm.meta.perPage) + " per page "),
                      _c("i", { staticClass: "fas fa-chevron-up" })
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.configs.system.paginations, function(option) {
                    return _c(
                      "a",
                      {
                        key: option,
                        staticClass: "dropdown-item",
                        on: {
                          click: function($event) {
                            _vm.limit = option
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "font-weight-700" }, [
                          _vm._v(_vm._s(option))
                        ]),
                        _vm._v(" per page")
                      ]
                    )
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex justify-content-end align-items-center"
                },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: _vm.pagination.align,
                      size: _vm.pagination.size,
                      "total-rows": _vm.meta.total,
                      "per-page": _vm.meta.perPage
                    },
                    model: {
                      value: _vm.page,
                      callback: function($$v) {
                        _vm.page = $$v
                      },
                      expression: "page"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }