var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "custom-control custom-radio",
      class: [
        _vm.inlineClass,
        { disabled: _vm.disabled },
        { "has-image": _vm.hasImage },
        _vm.type
      ]
    },
    [
      _c("input", {
        staticClass: "custom-control-input",
        attrs: { id: _vm.cbId, type: "radio", disabled: _vm.disabled },
        domProps: { checked: _vm.shouldBeChecked, value: _vm.value },
        on: { change: _vm.updateInput }
      }),
      _vm._v(" "),
      _c(
        "label",
        { staticClass: "custom-control-label", attrs: { for: _vm.cbId } },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }