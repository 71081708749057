var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: _vm.transition } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isActive,
            expression: "isActive"
          }
        ],
        ref: "animatedLoader",
        staticClass: "al-container",
        class: [
          "al-size-" + _vm.size,
          { "al-overlay": _vm.withOverlay },
          "al-overlay-" + _vm.overlayColor
        ]
      },
      [
        _c(
          "div",
          { staticClass: "al-loader", style: _vm.loaderContainerStyles },
          [
            _vm._t("default", [
              _c(_vm.loaderType, {
                tag: "component",
                attrs: {
                  background: _vm.loaderBackground,
                  color: _vm.loaderColor,
                  size: _vm.loaderSize,
                  width: _vm.loaderWidth,
                  height: _vm.loaderHeight,
                  stroke: _vm.loaderStroke,
                  speed: _vm.loaderSpeed
                }
              })
            ])
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }