var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeDropDown,
          expression: "closeDropDown"
        }
      ],
      tag: "component",
      class: [
        "dropdown",
        { show: _vm.isOpen },
        { dropdown: _vm.direction === "down" },
        { dropup: _vm.direction === "up" }
      ],
      attrs: { "aria-haspopup": "true", "aria-expanded": _vm.isOpen },
      on: {
        click: _vm.componentClicked,
        mouseover: _vm.handleMouseover,
        mouseout: _vm.handleMouseout
      }
    },
    [
      _vm._t("title", [
        _c(
          "a",
          {
            staticClass: "dropdown-toggle nav-link",
            class: { "no-caret": _vm.hideArrow },
            attrs: { "data-toggle": "dropdown" }
          },
          [
            _c("i", { class: _vm.icon }),
            _vm._v(" "),
            _c("span", { staticClass: "no-icon" }, [_vm._v(_vm._s(_vm.title))])
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        _vm.dropdownMenuTag,
        {
          tag: "component",
          class: [
            "dropdown-menu",
            { "dropdown-menu-right": _vm.position === "right" },
            { show: _vm.isOpen },
            "dropdown-" + _vm.size,
            _vm.menuClasses,
            "dropdown-" + _vm.design,
            "dropdown-dir-" + _vm.direction
          ]
        },
        [
          _c(
            "div",
            {
              class: ["dropdown-menu-items-wrapper", { scroll: _vm.hasScroll }]
            },
            [
              _c(
                "div",
                { staticClass: "dropdown-menu-items-content" },
                [
                  _c(
                    "vue-scroll",
                    {
                      ref: "vue-scroll-" + _vm.cbId,
                      attrs: { id: "vue-scroll-" + _vm.cbId }
                    },
                    [
                      _vm._t("withScope", null, { isOpen: _vm.isOpen }),
                      _vm._v(" "),
                      _vm._t("default")
                    ],
                    2
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }