var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "base-input" },
    [
      _c(
        "div",
        {
          staticClass: "form-group",
          class: [
            { "input-group": _vm.hasIcon },
            _vm.sizeClass,
            { focused: _vm.focused },
            { "input-group-alternative": _vm.alternative && !_vm.native },
            { "input-group-material": !(_vm.native || _vm.alternative) },
            { "is-naked": _vm.naked },
            { "has-label": _vm.label || _vm.$slots.label },
            { "has-success": _vm.valid === true },
            { "has-danger": _vm.error || _vm.valid === false },
            { "not-empty": _vm.value || _vm.value === 0 },
            { required: _vm.required },
            _vm.wrapperClasses
          ]
        },
        [
          (_vm.computedCurrency ||
            _vm.addonLeftText ||
            _vm.addonLeftIcon ||
            _vm.$slots.addonLeft) &&
          !_vm.hidePrefixSuffix
            ? _c("div", { staticClass: "input-group-prepend" }, [
                _c(
                  "span",
                  { staticClass: "input-group-text" },
                  [
                    _vm._t("addonLeft", [
                      _vm.computedCurrency && !_vm.hidePrefixSuffix
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.computedCurrency.prefix))
                          ])
                        : _vm.addonLeftText
                        ? _c("span", [_vm._v(_vm._s(_vm.addonLeftText))])
                        : _c("i", { class: _vm.addonLeftIcon })
                    ])
                  ],
                  2
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._t("label", [
            _vm.label || _vm.label === " "
              ? _c(
                  "label",
                  {
                    class: _vm.labelClasses,
                    attrs: {
                      for:
                        "base-input-" +
                        (_vm.$attrs.name ? _vm.$attrs.name : _vm.cbId)
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.label) +
                        "\n                "
                    ),
                    _vm.required
                      ? _c("span", { staticClass: "required-asterix" }, [
                          _vm._v("*")
                        ])
                      : _vm._e()
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm._t(
            "default",
            [
              _vm.$attrs.type === "number"
                ? [
                    _c(
                      "input",
                      _vm._g(
                        _vm._b(
                          {
                            directives: [
                              {
                                name: "auto-focus",
                                rawName: "v-auto-focus",
                                value: _vm.autoFocus,
                                expression: "autoFocus"
                              },
                              {
                                name: "select-on-focus",
                                rawName: "v-select-on-focus",
                                value: _vm.selectOnFocus,
                                expression: "selectOnFocus"
                              },
                              {
                                name: "translate-on-blur",
                                rawName: "v-translate-on-blur",
                                value: _vm.translateOnBlur,
                                expression: "translateOnBlur"
                              }
                            ],
                            ref:
                              "base-input-" +
                              (_vm.$attrs.name ? _vm.$attrs.name : _vm.cbId),
                            class: [
                              "form-control",
                              { "is-valid": !_vm.error && _vm.valid === true },
                              {
                                "is-invalid": _vm.error || _vm.valid === false
                              },
                              _vm.inputClasses
                            ],
                            attrs: {
                              id:
                                "base-input-" +
                                (_vm.$attrs.name ? _vm.$attrs.name : _vm.cbId),
                              autofocus: _vm.autoFocus,
                              "aria-describedby": "addon-right addon-left",
                              min: _vm.$attrs.min
                                ? _vm.$attrs.min
                                : _vm.format === "currency"
                                ? 0
                                : null,
                              step: _vm.computedStep
                            },
                            domProps: { value: _vm.value }
                          },
                          "input",
                          _vm.$attrs,
                          false
                        ),
                        _vm.listeners
                      )
                    )
                  ]
                : [
                    _c(
                      "input",
                      _vm._g(
                        _vm._b(
                          {
                            directives: [
                              {
                                name: "auto-focus",
                                rawName: "v-auto-focus",
                                value: _vm.autoFocus,
                                expression: "autoFocus"
                              },
                              {
                                name: "select-on-focus",
                                rawName: "v-select-on-focus",
                                value: _vm.selectOnFocus,
                                expression: "selectOnFocus"
                              },
                              {
                                name: "translate-on-blur",
                                rawName: "v-translate-on-blur",
                                value: _vm.translateOnBlur,
                                expression: "translateOnBlur"
                              }
                            ],
                            ref:
                              "base-input-" +
                              (_vm.$attrs.name ? _vm.$attrs.name : _vm.cbId),
                            class: [
                              "form-control",
                              { "is-valid": !_vm.error && _vm.valid === true },
                              {
                                "is-invalid": _vm.error || _vm.valid === false
                              },
                              _vm.inputClasses
                            ],
                            attrs: {
                              id:
                                "base-input-" +
                                (_vm.$attrs.name ? _vm.$attrs.name : _vm.cbId),
                              autofocus: _vm.autoFocus,
                              "aria-describedby": "addon-right addon-left"
                            },
                            domProps: { value: _vm.value }
                          },
                          "input",
                          _vm.$attrs,
                          false
                        ),
                        _vm.listeners
                      )
                    )
                  ]
            ],
            null,
            _vm.slotData
          ),
          _vm._v(" "),
          (_vm.computedCurrency ||
            _vm.addonRightText ||
            _vm.addonRightIcon ||
            _vm.$slots.addonRight) &&
          !_vm.hidePrefixSuffix
            ? _c("div", { staticClass: "input-group-append" }, [
                _c(
                  "span",
                  { staticClass: "input-group-text" },
                  [
                    _vm._t("addonRight", [
                      _vm.addonRightIcon === "far fa-calendar-alt" &&
                      _vm.value &&
                      _vm.allowEmpty
                        ? _c(
                            "a",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              staticClass: "clear-date",
                              attrs: { title: _vm.$t("general.clear") },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.$emit("input", "")
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-times" })]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.computedCurrency && !_vm.hidePrefixSuffix
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.computedCurrency.suffix))
                          ])
                        : _vm.addonRightText
                        ? _c("span", [_vm._v(_vm._s(_vm.addonRightText))])
                        : _c("i", { class: _vm.addonRightIcon })
                    ])
                  ],
                  2
                )
              ])
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("infoBlock"),
      _vm._v(" "),
      _vm._t("errorBlock", [
        _vm.error
          ? _c(
              "div",
              {
                staticClass: "text-danger invalid-feedback",
                staticStyle: { display: "block" }
              },
              [_vm._v("\n            " + _vm._s(_vm.error) + "\n        ")]
            )
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }