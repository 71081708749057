var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "base-textarea" },
    [
      _c(
        "div",
        {
          staticClass: "form-group",
          class: [
            { focused: _vm.focused },
            { "input-group-alternative": _vm.alternative && !_vm.native },
            { "input-group-material": !(_vm.native || _vm.alternative) },
            { "has-label": _vm.label || _vm.$slots.label },
            { "has-placeholder": _vm.placeholder },
            { "has-success": _vm.valid === true },
            { "has-danger": _vm.error || _vm.valid === false },
            {
              "not-empty": _vm.slotData.taValue && _vm.slotData.taValue.length
            },
            { required: _vm.required },
            _vm.wrapperClasses
          ]
        },
        [
          _vm._t("label", [
            _vm.label
              ? _c(
                  "label",
                  {
                    class: _vm.labelClasses,
                    attrs: {
                      for:
                        "base-textarea-" +
                        (_vm.$attrs.name ? _vm.$attrs.name : _vm.cbId)
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.label) +
                        "\n                "
                    ),
                    _vm.required
                      ? _c("span", { staticClass: "required-asterix" }, [
                          _vm._v("*")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.charCount
                      ? _c("span", { staticClass: "bracketed" }, [
                          _vm._v(
                            _vm._s(_vm.charsCounted) +
                              " " +
                              _vm._s(_vm.charCountLabel)
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.lineCount
                      ? _c("span", { staticClass: "bracketed" }, [
                          _vm._v(
                            _vm._s(_vm.linesCounted) +
                              " " +
                              _vm._s(_vm.lineCountLabel)
                          )
                        ])
                      : _vm._e()
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm._t(
            "default",
            [
              _c(
                "b-form-textarea",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "auto-focus",
                        rawName: "v-auto-focus",
                        value: _vm.autoFocus,
                        expression: "autoFocus"
                      }
                    ],
                    staticClass: "form-control",
                    class: [
                      { "is-valid": _vm.valid === true },
                      { "is-invalid": _vm.valid === false },
                      _vm.inputClasses
                    ],
                    attrs: {
                      id:
                        "base-textarea-" +
                        (_vm.$attrs.name ? _vm.$attrs.name : _vm.cbId),
                      autofocus: _vm.autoFocus,
                      placeholder: _vm.placeholder
                    },
                    on: { input: _vm.updateValue },
                    nativeOn: {
                      focus: function($event) {
                        return _vm.onFocus($event)
                      },
                      blur: function($event) {
                        return _vm.onBlur($event)
                      }
                    },
                    model: {
                      value: _vm.slotData.taValue,
                      callback: function($$v) {
                        _vm.$set(_vm.slotData, "taValue", $$v)
                      },
                      expression: "slotData.taValue"
                    }
                  },
                  "b-form-textarea",
                  _vm.$attrs,
                  false
                )
              )
            ],
            null,
            _vm.slotData
          )
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("infoBlock"),
      _vm._v(" "),
      _vm._t("errorBlock", [
        _vm.error
          ? _c(
              "div",
              {
                staticClass: "text-danger invalid-feedback",
                staticStyle: { display: "block" }
              },
              [_vm._v("\n            " + _vm._s(_vm.error) + "\n        ")]
            )
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }