var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "multiselect-wrapper",
        { "not-empty": _vm.isSelected },
        { required: _vm.required }
      ]
    },
    [
      _c("label", { staticClass: "input-group-material-label" }, [
        _vm._v(_vm._s(_vm.label) + " "),
        _vm.required
          ? _c("span", { staticClass: "required-asterix" }, [_vm._v("*")])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm._t("addNew", [
        _vm.addNew && _vm.hasPermission(_vm.neededPermission)
          ? _c(
              "div",
              { staticClass: "add-new-link" },
              [
                _c(
                  "base-button",
                  {
                    attrs: { type: "button", design: "link", size: "sm" },
                    on: {
                      click: function($event) {
                        return _vm.$router.push({ name: _vm.addNew })
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("general.add_new")))]
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm._t("errorBlock", [
        _vm.error
          ? _c(
              "div",
              {
                staticClass: "text-danger invalid-feedback",
                staticStyle: { display: "block", "margin-top": "-0.5rem" }
              },
              [_vm._v("\n            " + _vm._s(_vm.error) + "\n        ")]
            )
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }