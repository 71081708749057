var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value.length
    ? _c(
        "div",
        { staticClass: "base-view view-uploads" },
        [
          _c(
            "div",
            {
              staticClass: "view-group",
              class: [
                { "has-label": _vm.label || _vm.$slots.label },
                { "has-success": _vm.valid === true },
                { "has-danger": _vm.error || _vm.valid === false },
                { "not-empty": _vm.value || _vm.value === 0 }
              ]
            },
            [
              _vm._t("label", [
                _vm.label
                  ? _c("label", { class: _vm.labelClasses }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.label) +
                          "\n            "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm._t("default", [
                _c("div", { staticClass: "files-list-wrapper" }, [
                  _c(
                    "ul",
                    { staticClass: "files-list" },
                    _vm._l(_vm.value, function(fileObj, index) {
                      return _c("li", { staticClass: "file-details-row" }, [
                        _c("div", { staticClass: "file-icon" }, [
                          _c("span", [
                            _c("i", {
                              class: ["far", _vm.getFileIcon(fileObj.mime)]
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "file-details" }, [
                          _c("h6", [_vm._v(_vm._s(fileObj.filename))]),
                          _vm._v(" "),
                          fileObj.error
                            ? _c("p", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(fileObj.error) +
                                    "\n                            "
                                )
                              ])
                            : _c("p", [
                                _c("span", [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-700" },
                                    [_vm._v(_vm._s(_vm.$t("upload.type")))]
                                  ),
                                  _vm._v(
                                    ": " +
                                      _vm._s(
                                        _vm.mimeTypes[fileObj.mime] || "Unknown"
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml-1" }, [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-700" },
                                    [_vm._v(_vm._s(_vm.$t("upload.size")))]
                                  ),
                                  _vm._v(
                                    ": " +
                                      _vm._s(
                                        _vm._f("decimal")(
                                          fileObj.size / 1024,
                                          0
                                        )
                                      ) +
                                      " KB"
                                  )
                                ])
                              ])
                        ]),
                        _vm._v(" "),
                        _c("div", { class: ["actions-wrapper"] }, [
                          _c("div", { staticClass: "actions-buttons" }, [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.left",
                                    modifiers: { hover: true, left: true }
                                  }
                                ],
                                class: [
                                  "action-btn download-btn",
                                  _vm.downloadButtonClasses
                                ],
                                attrs: {
                                  href:
                                    _vm.urlPrefix +
                                    "/" +
                                    fileObj.uuid +
                                    "?file=" +
                                    fileObj.filename,
                                  target: "_blank",
                                  title: _vm.$t("global.download", {
                                    attribute: _vm.nameLabel
                                  })
                                }
                              },
                              [_c("i", { staticClass: "fas fa-download" })]
                            )
                          ])
                        ])
                      ])
                    }),
                    0
                  )
                ])
              ])
            ],
            2
          ),
          _vm._v(" "),
          _vm._t("infoBlock"),
          _vm._v(" "),
          _vm._t("errorBlock", [
            _vm.error
              ? _c(
                  "div",
                  {
                    staticClass: "text-danger invalid-feedback",
                    staticStyle: { display: "block" }
                  },
                  [_vm._v("\n            " + _vm._s(_vm.error) + "\n        ")]
                )
              : _vm._e()
          ])
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }