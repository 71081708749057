var render = function() {
  var _obj, _obj$1, _obj$2, _obj$3, _obj$4
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card",
      class: [
        { "card-lift--hover": _vm.lift },
        { shadow: _vm.shadow },
        ((_obj = {}),
        (_obj["shadow-" + _vm.shadowSize] = _vm.shadowSize),
        _obj),
        ((_obj$1 = {}),
        (_obj$1[
          "shadow" + (_vm.shadowSize ? "-" + _vm.shadowSize : "") + "--hover"
        ] = _vm.raise),
        _obj$1),
        ((_obj$2 = {}),
        (_obj$2["bg-gradient-" + _vm.gradient] = _vm.gradient),
        _obj$2),
        ((_obj$3 = {}), (_obj$3["bg-" + _vm.type] = _vm.type), _obj$3),
        ((_obj$4 = {}),
        (_obj$4["border-color-" + _vm.border] = _vm.border),
        _obj$4)
      ]
    },
    [
      _vm._t("topImage", [
        _vm.image && _vm.imageOnTop
          ? _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value: _vm.image,
                  expression: "image"
                }
              ],
              staticClass: "card-img-top",
              attrs: { alt: _vm.imageAlt }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      !(_vm.$slots.topImage || (_vm.image && _vm.imageOnTop)) &&
      (_vm.$slots.header || _vm.$slots.title || _vm.$slots.actions || _vm.title)
        ? _c(
            "div",
            { staticClass: "card-header", class: _vm.headerClasses },
            [
              _vm._t("header", [
                _c(
                  "div",
                  { staticClass: "card-title" },
                  [_vm._t("title", [_c("h5", [_vm._v(_vm._s(_vm.title))])])],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-actions" },
                  [
                    _vm._t("actions", [
                      _vm.canRefresh
                        ? _c(
                            "button",
                            {
                              staticClass: "btn action",
                              attrs: { type: "button" },
                              on: { click: _vm.actionRefresh }
                            },
                            [
                              _c("i", {
                                class: [
                                  "fas fa-sync-alt",
                                  { "fa-spin": _vm.refreshing }
                                ]
                              })
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canMaximize
                        ? _c(
                            "button",
                            {
                              staticClass: "btn action",
                              attrs: { type: "button" },
                              on: { click: _vm.actionMaximize }
                            },
                            [_c("i", { staticClass: "fas fa-expand" })]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canMinimize
                        ? _c(
                            "button",
                            {
                              staticClass: "btn action",
                              attrs: { type: "button" },
                              on: { click: _vm.actionMinimize }
                            },
                            [_c("i", { staticClass: "fas fa-window-minimize" })]
                          )
                        : _vm._e()
                    ])
                  ],
                  2
                )
              ])
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.noBody
        ? _c(
            "div",
            { staticClass: "card-body", class: _vm.bodyClasses },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.noBody ? _vm._t("default") : _vm._e(),
      _vm._v(" "),
      _vm._t("bottomImage", [
        _vm.image && !_vm.imageOnTop
          ? _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value: _vm.image,
                  expression: "image"
                }
              ],
              staticClass: "card-img-bottom",
              attrs: { alt: _vm.imageAlt }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      !(_vm.$slots.bottomImage || (_vm.image && !_vm.imageOnTop)) &&
      _vm.$slots.footer
        ? _c(
            "div",
            { staticClass: "card-footer", class: _vm.footerClasses },
            [_vm._t("footer")],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }