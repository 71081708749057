var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.hideIfEmpty ||
    (_vm.hideIfEmpty && _vm.value !== null && _vm.value !== "")
    ? _c(
        "div",
        { staticClass: "base-view view-currency" },
        [
          _c(
            "div",
            {
              staticClass: "view-group",
              class: [
                { "has-label": _vm.label || _vm.$slots.label },
                { "not-empty": _vm.value || _vm.value === 0 }
              ]
            },
            [
              _vm._t("label", [
                _vm.label
                  ? _c("label", { class: _vm.labelClasses }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.label) +
                          "\n            "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm._t("main", [
                _vm.value || _vm.value === 0
                  ? _c(
                      _vm.tag,
                      {
                        tag: "component",
                        class: ["view-data no-wrap", _vm.dataClasses]
                      },
                      [
                        _vm.prefix
                          ? _c("span", { class: _vm.prefixOuterClasses }, [
                              _vm._v(_vm._s(_vm.prefix))
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "span",
                          { class: [_vm.prefixClasses, _vm.dataClasses] },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.hidePrefixSuffix
                                  ? ""
                                  : _vm.computedCurrency.prefix
                                  ? _vm.computedCurrency.prefix
                                  : _vm.computedCurrency.position === "prefix"
                                  ? _vm.computedCurrency.symbol
                                  : ""
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        [_vm._v(_vm._s(_vm.getValue()))],
                        _vm._v(" "),
                        _c(
                          "span",
                          { class: [_vm.suffixClasses, _vm.dataClasses] },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.hidePrefixSuffix
                                  ? ""
                                  : _vm.computedCurrency.suffix
                                  ? _vm.computedCurrency.suffix
                                  : _vm.computedCurrency.position === "suffix"
                                  ? _vm.computedCurrency.symbol
                                  : ""
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.suffix
                          ? _c("span", { class: _vm.suffixOuterClasses }, [
                              _vm._v(_vm._s(_vm.suffix))
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._t("badge")
                      ],
                      2
                    )
                  : _c(
                      "span",
                      { class: ["view-data no-wrap", _vm.dataClasses] },
                      [_vm._v("-")]
                    )
              ])
            ],
            2
          ),
          _vm._v(" "),
          _vm._t("errorBlock")
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }