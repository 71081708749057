var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.hideIfEmpty ||
    (_vm.hideIfEmpty && _vm.value !== null && _vm.value !== "")
    ? _c(
        "div",
        { staticClass: "base-view view-single view-date" },
        [
          _c(
            "div",
            {
              staticClass: "view-group",
              class: [
                { "has-label": _vm.label || _vm.$slots.label },
                { "is-entity": _vm.isEntity },
                { "not-empty": _vm.value || _vm.value === 0 },
                _vm.viewGroupClasses
              ]
            },
            [
              _vm._t("label", [
                _vm.label
                  ? _c("label", { class: _vm.labelClasses }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.label) +
                          "\n            "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm._t("main", [
                _c(
                  "div",
                  { staticClass: "view-data-wrapper" },
                  [
                    _vm.value
                      ? _c(
                          _vm.tag,
                          {
                            tag: "component",
                            class: [
                              "view-data",
                              { focusable: _vm.focusable },
                              _vm.dataClasses
                            ]
                          },
                          [
                            _vm.showIcon && _vm.icon
                              ? _c("div", { staticClass: "icon-wrapper" }, [
                                  _c("i", { class: _vm.icon })
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.prefix
                              ? _c("span", { class: _vm.prefixClasses }, [
                                  _vm._v(_vm._s(_vm.prefix) + " ")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.withTz
                              ? _c(
                                  "span",
                                  [
                                    _vm.showIfExists
                                      ? [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm._f("momentDateTimeTz")(
                                                  _vm.value[_vm.showIfExists]
                                                )
                                              ) +
                                              "\n                        "
                                          )
                                        ]
                                      : [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("momentDateTimeTz")(
                                                _vm.value
                                              )
                                            )
                                          )
                                        ]
                                  ],
                                  2
                                )
                              : _c(
                                  "span",
                                  [
                                    _vm.showIfExists
                                      ? [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm._f("moment")(
                                                  [
                                                    _vm.value[_vm.showIfExists],
                                                    _vm.parseFormat
                                                  ],
                                                  _vm.outputFormat
                                                )
                                              ) +
                                              "\n                        "
                                          )
                                        ]
                                      : [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("moment")(
                                                [_vm.value, _vm.parseFormat],
                                                _vm.outputFormat
                                              )
                                            )
                                          )
                                        ]
                                  ],
                                  2
                                ),
                            _vm._v(" "),
                            _vm.suffix
                              ? _c("span", { class: _vm.suffixClasses }, [
                                  _vm._v(" " + _vm._s(_vm.suffix))
                                ])
                              : _vm._e()
                          ]
                        )
                      : _c("span", [_vm._v("-")])
                  ],
                  1
                )
              ])
            ],
            2
          ),
          _vm._v(" "),
          _vm._t("errorBlock")
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }