var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["table-wrapper"] }, [
    _c(
      "div",
      {
        class: [_vm.isEmpty ? "" : "min-height-400px"],
        attrs: { id: _vm.tableWrapperId }
      },
      [_vm._t("default")],
      2
    ),
    _vm._v(" "),
    !_vm.filtered && _vm.isEmpty
      ? _c("div", { staticClass: "empty-table text-muted" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("h2", {
            staticClass: "text-muted",
            domProps: {
              textContent: _vm._s(
                _vm.$t("global.list", { attribute: _vm.$t(_vm.entitiesTitle) })
              )
            }
          }),
          _vm._v(" "),
          _c("p", {
            domProps: { textContent: _vm._s(_vm.$t(_vm.entityDescription)) }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-5" },
            [
              _vm._t("button", [
                (!_vm.addButtonPermissions ||
                  _vm.hasAllPermissions(_vm.addButtonPermissions)) &&
                _vm.addButtonRoute &&
                _vm.addButtonLabel
                  ? _c(
                      "base-button",
                      {
                        attrs: { type: "button", design: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              name: _vm.addButtonRoute,
                              params: _vm.addButtonParams,
                              query: _vm.addButtonQuery
                            })
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fas fa-plus" }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(_vm.addButtonLabel, {
                                attribute: _vm.$t(_vm.entityTitle)
                              })
                            )
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ],
            2
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.filtered && _vm.isEmpty
      ? _c("div", { staticClass: "empty-table text-muted" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("h2", {
            staticClass: "text-muted",
            domProps: {
              textContent: _vm._s(
                _vm.$t("global.could_not_find", {
                  attribute: _vm.$t(_vm.entityTitle)
                })
              )
            }
          }),
          _vm._v(" "),
          _vm.showReset
            ? _c(
                "div",
                { staticClass: "mt-5" },
                [
                  _c(
                    "base-button",
                    {
                      attrs: { type: "button", design: "primary" },
                      on: { click: _vm.reset }
                    },
                    [_vm._v(_vm._s(_vm.$t("general.clear")))]
                  )
                ],
                1
              )
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isEmpty
      ? _c("div", { staticClass: "my-5" }, [_vm._t("instructions")], 2)
      : _vm._e(),
    _vm._v(" "),
    !_vm.isEmpty && _vm.showFooter
      ? _c(
          "div",
          {
            class: [
              "list-pagination-component",
              { "small-footer": _vm.smallFooter }
            ]
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "pagination-status-component flex-grow text-muted small"
              },
              [
                _c(
                  "div",
                  [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("global.total_result_found_with_record", {
                            attribute: _vm.meta.total,
                            from: _vm.meta.from,
                            to: _vm.meta.to
                          })
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _vm.filtered
                      ? [
                          _c(
                            "p",
                            [
                              _vm._v(
                                _vm._s(_vm.$t("general.filtered_data")) +
                                  " - \n                    "
                              ),
                              _c(
                                "base-button",
                                {
                                  staticClass: "ml-1 inline",
                                  attrs: {
                                    type: "button",
                                    design: "link",
                                    size: "sm"
                                  },
                                  on: { click: _vm.reset }
                                },
                                [_vm._v(_vm._s(_vm.$t("general.clear_filter")))]
                              )
                            ],
                            1
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "base-dropdown",
              {
                staticClass: "per-page-component",
                attrs: {
                  tag: "div",
                  direction: "up",
                  "menu-classes": "show-dropdown-up",
                  position: "right"
                }
              },
              [
                _c(
                  "base-button",
                  {
                    staticClass: "mr-3",
                    attrs: {
                      slot: "title",
                      type: "button",
                      size: _vm.pagination.size,
                      design: "primary",
                      "data-toggle": "dropdown",
                      role: "button"
                    },
                    slot: "title"
                  },
                  [
                    _vm._v(_vm._s(_vm.meta.perPage) + " per page "),
                    _c("i", { staticClass: "fas fa-chevron-up" })
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.configs.system.paginations, function(option) {
                  return _c(
                    "a",
                    {
                      key: option,
                      staticClass: "dropdown-item",
                      on: {
                        click: function($event) {
                          _vm.perPage = option
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "font-weight-700" }, [
                        _vm._v(_vm._s(option))
                      ]),
                      _vm._v(" per page")
                    ]
                  )
                })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pagination-buttons-component" },
              [
                _c("b-pagination", {
                  attrs: {
                    align: _vm.pagination.align,
                    size: _vm.pagination.size,
                    "total-rows": _vm.meta.total,
                    "per-page": _vm.meta.perPage
                  },
                  model: {
                    value: _vm.currentPage,
                    callback: function($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-5" }, [
      _c("i", { staticClass: "fas fa-list fa-5x" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-5" }, [
      _c("i", { staticClass: "far fa-frown fa-5x" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }